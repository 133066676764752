import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { DialogService } from '@progress/kendo-angular-dialog';
import { SelectionEvent } from '@progress/kendo-angular-grid';
import {
  orderBy,
  process,
  SortDescriptor,
  State,
} from '@progress/kendo-data-query';
import { ToastrManager } from 'ng6-toastr-notifications';
import { AdministrationService } from 'src/app/common/services/administration/administration.service';

@Component({
  selector: 'app-manage-transaction-lines',
  templateUrl: './manage-transaction-lines.component.html',
  styleUrls: ['./manage-transaction-lines.component.scss'],
})
export class ManageTransactionLinesComponent implements OnInit {
  constructor(
    private adminService: AdministrationService,
    private router: Router,
    private dialogService: DialogService,
    public toastr: ToastrManager,
    private fb: FormBuilder
  ) {}
  public selectedYear: number = 0;
  ngOnInit(): void {
    let year: any = localStorage.getItem('selectedFiscalYear');
    this.selectedYear = JSON.parse(year).FiscalYearId;
    this.getAllTransactionItems(this.selectedYear);
  }

  public transactionitemList: any = [];
  public gridData: any = [];
  public isLoading: boolean = false;
  public messageIncludeTran =
    'All transactions and journals for the selected GL Accounts will be permanently deleted. \n\nAny associated Offline Referrals will need to be manually removed. \n\nAre you sure you want to continue?';
  public messageExcludeTran =
    'All trial balance, transactions and journals for the selected GL Accounts will be permanently deleted. \n\nAny associated Offline Referrals will need to be manually removed. \n\nAre you sure you want to continue?';
  public messageSelectGLAccount =
    'Please select the GL account(s) to be deleted';
  public messagePop: string = '';
  public warnondelete: boolean = false;
  public ondeleteTran: boolean = true;

  public sort: SortDescriptor[] = [
    {
      field: '_ID',
      dir: 'asc',
    },
  ];

  public sortChange(sort: SortDescriptor[]): void {
    this.sort = sort;
    this.transactionitemList = {
      data: orderBy(this.gridData, this.sort),
      total: this.gridData.length,
    };
  }

  getAllTransactionItems(fiscalYear: number): void {
    this.isLoading = true;
    this.adminService
      .getTransactionsItems(fiscalYear)
      .then((response: any[]) => {
        this.transactionitemList = response;
        this.gridData = response;
        this.isLoading = false;
      })
      .catch((err: any) => {
        this.isLoading = false;
      });
  }

  public onFilter(event: { originEvent: any; queryStr: string }): void {
    const inputValue = event.queryStr;
    this.transactionitemList = process(this.gridData, {
      filter: {
        logic: 'or',
        filters: [
          {
            field: 'AccountNumber',
            operator: 'contains',
            value: inputValue,
          },
          {
            field: 'LongDescription',
            operator: 'contains',
            value: inputValue,
          },
          {
            field: 'Statement_Analysis',
            operator: 'contains',
            value: inputValue,
          },
        ],
      },
    }).data;
    if (inputValue == '') {
      let response = this.getAllTransactionItems(this.selectedYear);
    }
  }

  public transDeleteData: {
    RowId: any;
    StatutoryAccountCodeId: any;
    TransPeriod: any;
  }[] = [];

  public selectedTrialBalance: boolean = false;

  public onGridSelectionChange(event: SelectionEvent) {
    var count = event.selectedRows != undefined ? event.selectedRows.length : 0;
    var selectedRowItems = event.selectedRows != undefined ? event.selectedRows: null;
    if(selectedRowItems != null)
    {
      for (let i = 0; i < count; i++) {
        var itemRowId = selectedRowItems[i].dataItem.RowId;
        var itemStatAccCodeId = selectedRowItems[i].dataItem._ID; 
        var itemTransPeriod = selectedRowItems[i].dataItem.Period; 
        this.transDeleteData.push({
          RowId: itemRowId,
          StatutoryAccountCodeId: itemStatAccCodeId,
          TransPeriod: itemTransPeriod
          
        });
      }
    }
    count = event.deselectedRows != undefined ? event.deselectedRows.length : 0;
    var deSelectedRowItems = event.deselectedRows != undefined ? event.deselectedRows: null;
    if(deSelectedRowItems != null)
    {
        for (let i = 0; i < count; i++) {
          var itemRowId = deSelectedRowItems[i].dataItem.RowId;
          var index = this.transDeleteData.map((x) => x.RowId).indexOf(itemRowId);
          this.transDeleteData.splice(index, 1);
        }
    }
  }

  public deleteTransactionData() {
    this.ondeleteTran = true;
    if (
      this.transDeleteData == null ||
      this.transDeleteData == undefined ||
      this.transDeleteData.length == 0
    ) {
      this.messagePop = this.messageSelectGLAccount;
      this.ondeleteTran = false;
    } else {
      if (this.selectedTrialBalance) {
        this.messagePop = this.messageIncludeTran;
      } else {
        this.messagePop = this.messageExcludeTran;
      }
    }
    this.warnondelete = true;
  }

  public onTrialBalancechange(event: any) {
    this.selectedTrialBalance = event.checkboxState;
  }

  public cancelClick() {
    this.warnondelete = false;
    this.ondeleteTran = true;
  }

  public deleteTran() {
    this.isLoading = true;
    this.adminService
      .DeleteTrialBalanceData(
        this.transDeleteData,
        this.selectedYear,
        this.selectedTrialBalance
      )
      .subscribe(
        (res) => {
          this.isLoading = false;
          this.warnondelete = false;
          if (res == 'Transaction Deleted Successfully') {
            this.transDeleteData = [];
            this.ondeleteTran = true;
            this.getAllTransactionItems(this.selectedYear);
            this.toastr.successToastr(res, 'Success');
          } else if (res == 'Reference voilation') {
            this.messagePop = 'Reference voilation';
            this.ondeleteTran = false;
          } else {
            this.getAllTransactionItems(this.selectedYear);
          }
        },
        (error) => {
          this.isLoading = false;
          this.toastr.errorToastr('Failed to Delete Transactions', 'Error');
        }
      );
  }

  public AddColorFormat(textData: any) {
    if (textData.indexOf(')') > 0 || textData.indexOf('-') != -1) {
      return 'color: red; text-align: right; padding: 0px';
    } else {
      return 'text-align: right; padding: 0px';
    }
  }
}
